import React from 'react'
import { Link } from 'gatsby'
import { captureEvent } from 'event-service/modules/capture'
import "../scss/NavigationBar.scss"
import SearchBox from './searchBox/SearchBox'

function NavigationBar(props) {
    const { siteNav } = props;

    let mergedMenu = siteNav.menu.map((item, i) => { 
        if (item.children && item.children.length > 0) { // if a drop down go down a deeper level and build drop down code

            let subMenu = item.children.map((subItem, x) => {
                if (subItem.children && subItem.children.length > 0) {
                    let lowestMenuLevel = subItem.children.map((lowItem, idx) => {
                        return lowItem.link.linkPath.length > 0 ?
                            (
                                <li className={idx === (item.length - 1 ) ? "last" : ""} tabIndex={"0"}
                                onFocus={function(e){openFocusMenu(e.target)}}
                                onBlur={idx === (item.length - 1 ) ? function(e){closeFocusMenu(e.target)} : "" }>
                                    <Link id='nav_link' key={lowItem.link.linkName} className="nav-link nav-drop" to={lowItem.link.linkPath}>
                                        <span className="">{lowItem.link.linkName}</span>
                                    </Link>
                                </li>
                            ) : (
                                <li className={idx === (item.length - 1 ) ? "last" : ""} tabIndex={"0"}
                                onFocus={function(e){openFocusMenu(e.target)}}
                                onBlur={idx === (item.length - 1 ) ? function(e){closeFocusMenu(e.target)} : "" }>
                                    <p key={lowItem.link.linkName} className="nav-link nav-drop">
                                        <span className="">{lowItem.link.linkName}</span>
                                    </p>
                                </li>
                            )
                    })

                    return (
                        <li tabIndex={"0"} key={x} 
                        className={`nav-link nav-drop nav-secondDrop ${x === (item.length - 1 ) ? "last" : ""}`}
                        onFocus={function(e){openFocusMenu(e.target)}}
                        onBlur={x === (item.length - 1 ) ? function(e){closeFocusMenu(e.target)} : "" }
                        id={subItem.link.linkName + '-mobile'}
                        onClick={() => toggleDrop(subItem.link.linkName + '-mobile')}>
                            <span className="">{subItem.link.linkName} <svg className="nav-svg" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8"><g><g><path fill="none" stroke="var(--primary-accent)" stroke-miterlimit="20" stroke-width="2" d="M1 1v0l5 5v0l5-5v0"></path></g></g></svg></span>
                            <ul className="nav-dropdown">
                                {lowestMenuLevel}
                            </ul>
                        </li>

                    )
                } else {
                    return subItem.link.linkPath.length > 0 ?
                        (
                            <li className={x === (item.children.length - 1 ) ? "last" : ""} tabIndex={"0"}
                            onFocus={function(e){openFocusMenu(e.target)}}
                                onBlur={x === (item.children.length - 1 ) ? function(e){closeFocusMenu(e.target)} : "" }
                                onMouseLeave={function(e){checkPlacement(e.target)}}>
                                <Link id='nav_link' key={subItem.link.linkName} className="" to={subItem.link.linkPath}>
                                    <span className="">{subItem.link.linkName}</span>
                                </Link>
                            </li>
                        ) : (
                            <li className={x === (item.children.length - 1 ) ? "last" : ""} tabIndex={"0"}
                            onFocus={function(e){openFocusMenu(e.target)}}
                                onBlur={x === (item.children.length - 1 ) ? function(e){closeFocusMenu(e.target)} : "" }>
                                <p key={subItem.link.linkName} className="">
                                    <span className="">{subItem.link.linkName}</span>
                                </p>
                            </li>
                        )
                }
            })

            return (
                <li tabIndex={"0"} key={i} className="top-level" id={item.link.linkName + '-mobile'} 
                onMouseEnter={function(e){clearFocus(e.target)}}
                onFocus={function(e) {focusSelf(e.target);}}
                onClick={() => toggleDrop(item.link.linkName + '-mobile')}>
                    <span className="">{item.link.linkName} <svg className="nav-svg" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8"><g><g><path fill="none" stroke="var(--primary-accent)" stroke-miterlimit="20" stroke-width="2" d="M1 1v0l5 5v0l5-5v0"></path></g></g></svg></span>
                    <ul className="nav-dropdown">
                        {subMenu}
                    </ul>
                </li>
            )
        } else {
            return item.link.linkPath.length > 0 ? (
                <Link tabIndex={"0"} id='nav_link' key={item.link.linkName} className="" to={item.link.linkPath} onClick={() => toggleMobile()}>
                    <span className="">{item.link.linkName}</span>
                </Link>
            ) : (
                    <p tabIndex={"0"} key={item.link.linkName} className="" onClick={() => toggleMobile()}>
                        <span className="">{item.link.linkName}</span>
                    </p>
                )
        }
    })

    return (
        <header id="header">
            <div className="header site__section js-header">
                <section className="header__section site__subsection">
                    <Link id='nav_link' className="button button_type-icon header__logo icon_logo js-button nav-logo" to="/">
                        <img className="nav-icon" src={props.dealerInfo.dealerLogo} alt={props.dealerInfo.dealerName} />
                    </Link>
                </section>
                <section className='navigation-menu'>
                    <div id="navigation" className="menu-styles">
                        <ul className="list">
                            {mergedMenu}
                        </ul>
                        
                    </div>
                    
                </section>
                <section className="mobileNavCont">
            <SearchBox />    <a
          style={{alignItems:"center",justifyContent:"center",flexDirection:'column',gap:'10px'}}
          onClick={() => toggleMobile()} tabIndex={"0"}
          className="burger header__explore-menu js-header-explore-button"
        >
          <svg viewBox="0 0 100 80" width="35" height="35">
            <rect width="100" height="10" fill="var(--primary-text)"></rect>
            <rect y="30" width="70" height="10" fill="var(--primary-text)"></rect>
            <rect y="60" width="30" height="10" fill="var(--primary-text)"></rect>
          </svg>
          <div style={{color:"lightgray",fontSize:"10px"}}>MENU</div>
        </a></section>
                <section className="header__section site__subsection mobile_hidden js-menu">
                    <a onClick={() => {
                        captureEvent({ 'event': 'asc_click_to_call', 'comm_phone_number': `${props.dealerInfo.serviceNumber}`, 'department': 'sales'}, { handlers: ['ASC'] }).then(response => console.log(response)).catch(error => console.log(error))
                        captureEvent({ 'events': 'clickToCall', 'department': 'sales'}, { handlers: ['Maserati'] }).then(response => console.log(response)).catch(error => console.log(error))
                    }} href={'tel:' + props.dealerInfo.salesNumber} className="icon-spoiler submenu__explore-map js-icon-spoiler" data-target="js-mobile-map" data-id="map-spoiler">
                        <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 36 36">
                            <path className="st0" d="M35.6,30.2c-2,4.9-6.8,5.7-8.4,5.7c-0.5,0-7.6,0.4-17.8-9.3C1.2,18.8,0.2,10.4,0.1,8.8C0,7.2,0.4,3.3,5.8,0.4
                                        c0.7-0.4,2-0.5,2.3-0.2c0.2,0.2,4.7,7.6,4.8,7.8c0.1,0.2,0.2,0.5,0.2,0.7c0,0.3-0.2,0.8-0.7,1.3c-0.5,0.5-1,1-1.6,1.4
                                        c-0.6,0.4-1.1,0.9-1.6,1.3c-0.5,0.5-0.7,0.9-0.7,1.2c0,0.8,0.7,3.6,5.6,8c4.9,4.3,7.3,5.6,7.5,5.6c0.2,0.1,0.4,0.1,0.6,0.1
                                        c0.3,0,0.7-0.2,1.2-0.7c0.5-0.5,2.1-2.7,2.6-3.1c0.5-0.5,0.9-0.7,1.3-0.7c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,7.7,4.5,7.8,4.6
                                        C36.1,28.3,35.9,29.5,35.6,30.2"></path>
                        </svg>
                        <span className="linkSecondary" >{props.dealerInfo.salesNumber}</span>
                    </a>
                    <span className="icon-split"></span>
                    <span onClick={() => captureEvent({ events: 'getDirections' }, { handlers: ['Maserati'] }).then(res => console.log(res)).catch(err => console.log(err))}><Link id='nav_link' to={props.dealerInfo.directions} className="icon-spoiler directions submenu__explore-map js-icon-spoiler" data-target="js-mobile-map" data-id="map-spoiler">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 36 36"><g><g><path fill="#062e28" d="M15.038 22.155a7.192 7.192 0 0 1-7.192-7.193 7.192 7.192 0 0 1 7.192-7.192 7.192 7.192 0 0 1 7.193 7.192 7.192 7.192 0 0 1-7.193 7.193zM14.511.009C7.136.27 1.009 6.028.3 13.38a14.925 14.925 0 0 0 .297 4.723c.228 1.021.57 2.012.997 2.941 1.484 3.52 4.917 9.408 12.619 15.815a1.286 1.286 0 0 0 1.644 0C23.56 30.46 26.992 24.57 28.484 21.035c.434-.929.769-1.912.997-2.94a14.915 14.915 0 0 0 .365-3.276c0-8.365-6.918-15.107-15.335-14.81z"></path></g></g></svg>			        <div className="icon-spoiler__caption">
                            <div className="icon-spoiler__title">directions</div>
                        </div>
                    </Link></span>
                </section>
            </div>
        </header>
    )
}

function focusSelf(item) {
   // const div = document.getElementById(id)
   if(item.classList.contains('top-level')) {
    var items = document.querySelectorAll('.top-level')
    items.forEach(it => {
        if(it !== item)
        it.classList.remove('focus')
    })
    item.classList.add('focus')
   }
}
function blurSelf(item) {
    // const div = document.getElementById(id)
     item.classList.remove('focus')
 }

function openFocusMenu(item) {
    item.closest('.top-level').classList.add('focus')
}

function closeFocusMenu(item) {
    item.closest('.top-level').classList.remove('focus')
}

function clearFocus(menuItem) {
    var items = document.querySelectorAll('.top-level')
    items.forEach(item => {
        item.classList.remove('focus')
    })
    menuItem.classList.add('focus')
}

function checkPlacement(item){
    var topLevelHover = item.closest('.top-level').matches(':hover')
    if(topLevelHover) {

    }
    else {
        closeFocusMenu(item);
    }
}

function toggleDrop(id) {
    const div = document.getElementById(id)
    div.classList.toggle('showDrop')
}

function toggleClass(cl, element) {
    element.classList.toggle(cl)
}

function toggleMobile() {
    document.getElementById("navigation").classList.toggle('showMobile');


    document.getElementsByClassName("header__explore-menu")[0].classList.toggle('state_toggled');
}

export default NavigationBar