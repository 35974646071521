/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { createElement, Fragment }  from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function SEO({ description, lang, meta, title, data, tagString }) {
    const metaDescription = data.seo.MetaDesc || description
    const metaTitle = data.seo.MetaTitle || title

    const metaTags = data.seo.tags
    // Tags for Testing
    // [{"tagName": "SiteColor","tagContent":"Blue","id":"\"SiteColorBlue\"","editState":false},
    // {"tagName": "DealerID","tagContent":"314","id":"\"DealerID314\"","editState":false}]


    let tags = []
    metaTags.forEach(tag => tags.push({ "name": tag.tagName, "content": tag.tagContent }));

    const innerHTML = tagString.hasOwnProperty('tags') ? tagString.tags.toString() : '' 

    const arrTags = []
    if (typeof window !== 'undefined'){
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(innerHTML, 'text/html');
        const htmlChildren = htmlDoc.querySelector('head').children
        for (var i = 0; i < htmlChildren.length; i++) {
            arrTags.push(htmlChildren[i])
        }
    }

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    name: `title`,
                    content: metaTitle,
                }
            ].concat(meta).concat(tags)}
        >
            {arrTags.map(el => {
                var generatedEl;
                switch(el.nodeName.toLowerCase()) {
                    case "script":
                        return <script async={el.async} crossorigin={el.crossorigin} defer={el.defer} integrity={el.integrity} nomodule={el.nomodule} src={el.src} type={el.type} >{el.innerHTML}</script>
                    case "style":
                        return <style media={el.media} type={el.type}>{el.innerHTML}</style>
                    case "link":
                        return <link crossorigin={el.crossorigin} href={el.href} hreflang={el.hreflang} media={el.media} referrerpolicy={el.referrerpolicy} rel={el.rel} sizes={el.sizes} type={el.type} />
                    default:
                        break;
                }          
                return (
                    generatedEl
                )
            })}
            <title>{metaTitle}</title>
            {/* eslint-disable */}
            <script >{(typeof window !== "undefined") ? (function (i, s, o, g, r, a, m) {
                    i['ShiftAnalyticsObject'] = r; i[r] = i[r] || function () {
                    (i[r].q = i[r].q || []).push(arguments)
                    }, i[r].l = 1 * new Date(); a = s.createElement(o),
                    m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g;
                    m.parentNode.insertBefore(a, m)
                    })(window, document, 'script', '//maserati-na-tagging-staging.azureedge.net/scripts/sd.js', 'sdb') : null}
            </script>
            {/* eslint-enable */}
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO