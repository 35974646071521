import React from "react"
import { Link } from "gatsby"
import { captureEvent } from 'event-service/modules/capture'

import grid from '../../scss/flexboxgrid.module.scss'
import view from './footer.module.scss'
import CookiePolicy from "../cookie/CookiePolicy"

function Footer(props) {
  const { siteNav, dealerInfo, footer,footerClassName } = props
  const renderLinks = () => (
    <div className={view["foot-cont"]}>
      {
        footer.links.map((item, idx) => (
          <Link id='nav_link' key={idx} className={`${view["btn-text"]}`}
                to={item.linkValue}>
            <span>{item.linkName}</span>
          </Link>
        ))
      }
    </div>
  )
  return (
    <footer id="footer" className={view["footer"] + " " + (footerClassName === undefined ? "" : footerClassName)}>
      <div className={`${grid["row"]} ${view["foot-wrap"]}`}>
        <div className={`${grid["col-lg-12"]} ${view["foot-links"]} ${grid["col-md-8"]} ${grid["col-sm-8"]} `}>
          {renderLinks()}
        </div>
        <div className={`${grid["col-lg-12"]}  ${view["foot-links"]} ${grid["col-md-8"]} ${grid["col-sm-8"]}`} style={{ textAlign: "center" }}>
          <CookiePolicy dealerInfo={dealerInfo} CookieLinkText="Manage Cookie Policy" />
          <Link id='nav_link' to={"https://dealermasters.com/"} className={view["subtitle"]}>
            <p>&#169; {new Date().getFullYear()} {dealerInfo.dealerName}</p>
            <p style={{ display: "inline-block" }}>MADE WITH</p>
            <span className={view["heart"]} aria-label={"heart"} role={"img"}> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="none" stroke="var(--primaryAccent)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" style={{ verticalAlign: 'middle' }}>
  <path d="M12 21C12 21 7 16.5 5 14C2.5 11.5 2.5 8 5 6C7.5 3.5 11 4 12 6C13 4 16.5 3.5 19 6C21.5 8 21.5 11.5 19 14C17 16.5 12 21 12 21Z" fill="var(--primaryAccent)" />
</svg>
 </span>
            <p style={{ display: "inline-block" }}>BY DEALER MASTERS</p>
          </Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer